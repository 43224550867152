import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import "./dashboard.css"
import video_recorder from "../../static/Icon ionic-ios-videocam.svg";
import cross_mark from "../../static/Icon metro-cross.svg";
import { connect } from "react-redux"
import { loginPost } from '../../actions/login';
import cookies from "react-cookies";
import * as Constant from "../../constants";
import { Redirect } from 'react-router-dom';
import dashboardBackground from '../../static/dashboard_background.png'
import domesticContentImage from '../../static/Domestic-Solutions-Blog-Header-Full-size.png'

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeVideos: []
          };
        autoBind(this);

        const appElement = document.querySelector('.App');
        if(appElement){
            appElement.style.background = `transparent url(${dashboardBackground})`;
            appElement.style.backgroundRepeat =  'no-repeat';
            appElement.style.height = 'calc(100vh)';
            appElement.style.position = 'relative';
            appElement.style.backgroundSize = '110%';
            appElement.style.backgroundPosition = '100%';
            appElement.style.backgroundOrigin = 'padding-box';
            appElement.style.backgroundColor = 'transparent';
            appElement.style.opacity = '0.95';
            appElement.style.margin = '0';
        }
    }

    componentDidMount() {
        const data = {
            token: cookies.load(Constant.ACCESS_TOKEN)
        }
        this.props.loginPost(data);
    }

    handleVideoToggle = (id) => {
        this.setState((prevState) => ({
          activeVideos: prevState.activeVideos.includes(id)
            ? prevState.activeVideos.filter((vidId) => vidId !== id)
            : [...prevState.activeVideos, id],
        }));
      };


    listOfApplicationCard = (applicationList, activeVideos) => {
        const listOfApplications = Object.entries(applicationList).sort(([, aObj], [, bObj]) => aObj.order - bObj.order);
        let val = listOfApplications.map(([key, value], index) => {
            return <div key={key}>
                <div className={`application ${value.application_id}`}>
                    
                    {
                        activeVideos.includes(key) ?
                        (
                        <>
                            <div className="application-tutorial-video">
                                <div className="tutorial-content">
                                    <div className="tutorial-heading">
                                        <span>TUTORIAL VIDEO</span>
                                        <img src={cross_mark} onClick={() => this.handleVideoToggle(key)}
                                        className="cross-icon" height={13} width={23} alt="" />
                                    </div>
                                    <iframe
                                        title={value.name}
                                        src={value.application_tutorial_link}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        className="tutorial-video"
                                    ></iframe>
                                    <span className="application-name">{value.application_name}</span>
                                </div>
                            </div>
                        </>
                        ) :
                        (
                            <>
                                <img src={video_recorder} onClick={() => this.handleVideoToggle(key)}
                                    className="video-icon" height={13} width={23} alt="" />
                                <div className="application-image">
                                    <a href={this.getRedirectionUrl(value)} id="aTag" target="_blank" rel="noopener noreferrer">
                                        <img src={value.application_logo} alt="" className={value.application_name.toLocaleLowerCase() + `-image`}></img>
                                    </a>
                                </div>
                            </>
                        )
                    }
                    <div className="application-description">
                        <span>{value.application_description}</span>
                    </div>
                </div>
            </div>
        })
    
        return val;
    }
    
    getRedirectionUrl = (app) => {
        return app.has_access ? app.application_url : app.application_landing_page
    }
    
    render() {
        const isLoggedIn = this.props.isLoggedIn;
        console.log(this.state);
        if (isLoggedIn) {
            return (
                <Fragment>
                    <div className="dashboard">
                        <div className="domestic-solution-link">
                            <img src={domesticContentImage} height={140} width={220} 
                            onClick={() => window.open('https://twitter.com/Unirac', '_blank')} alt=""/>
                        </div>
                        <br/><br/><br/>
                        <div className="dashboard-heading">
                            SELECT TOOL TO START
                        </div>
                        <div className="applications">
                            {this.listOfApplicationCard(this.props.userInfo.application_list, this.state.activeVideos)}
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return <Redirect to="/login"></Redirect>
        }
    }
}

Dashboard.propTypes = {
    reviews: PropTypes.array,
}

function mapStateToProps(state, props) {
    return {
        userInfo: state.loginInfo.userInfo,
        isLoggedIn: state.loginInfo.isLoggedIn
    }
}
export default connect(mapStateToProps, { loginPost })(Dashboard);

